import { defineStore } from 'pinia'

import dayjs from 'dayjs/esm/index.js'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const filteredData = (data: any) => {
  return data?.filter((item) => {
    if (!item?.attributes?.opening_time) return false
    if (!item?.attributes?.closing_time) return false
    if (!item?.attributes?.weekdays) return false

    const weekDays = Object.values(item?.attributes?.weekdays)?.map(i => i?.toLowerCase()?.trim())
    const dayName = dayjs().format('dddd')?.toLowerCase()
    const weekDaysIncludesDayName = weekDays?.includes(dayName)

    const openingTime = item?.attributes?.opening_time?.split(':')?.join('')
    const closingTime = item?.attributes?.closing_time?.split(':')?.join('')
    const currentTime = dayjs().tz('Europe/Amsterdam').format('HH:mm:ss')?.split(':')?.join('')
    const isBetweenOpeningAndClosingTime = currentTime >= openingTime && currentTime <= closingTime

    const todayIsBetweenOpeningAndClosingTime = dayjs().isSameOrAfter(item?.attributes?.date_start, 'day') && dayjs().isSameOrBefore(item?.attributes?.date_end, 'day')

    return weekDaysIncludesDayName && isBetweenOpeningAndClosingTime && todayIsBetweenOpeningAndClosingTime
  })
}

const isOpen = (data: any) => {
  return filteredData(data).length > 0
}

const getOpeningHoursForToday = (data: any) => {
  if (!data) return null
  if (!isOpen(data)) return null

  return filteredData(data)?.map((item) => {
    return {
      openingTime: item?.attributes?.opening_time,
      closingTime: item?.attributes?.closing_time
    }
  })[0]
}

export const useMainStore = defineStore('main', {
  state: () => ({
    openingTimesData: null
  }),
  actions: {
    async fetchOpeningTimesData() {
      if (this.openingTimesData) return this.openingTimesData

      const { data }: any = await $fetch('/api/strapi-rest',
        { query: { path: queryOpeningTimesCurrent() } }
      )

      if (data) {
        this.openingTimesData = {
          data: data?.filter(i => i?.attributes?.weekdays?.length ),
          isOpen: isOpen(data),
          openingsHoursForToday: getOpeningHoursForToday(data)
        };

        return this.openingTimesData;
      }
    },
  }
})
