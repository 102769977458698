import qs from 'qs'

import dayjs from 'dayjs/esm/index.js'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)
const now = dayjs().tz('Europe/Amsterdam').format('YYYY-MM-DD')

export const queryOpeningTimesCurrent = () => {
  const query = qs.stringify(
    {
      publicationState: 'live',
      sort: ['date_start:asc', 'date_end:desc'],
      filters: {
        $or: [
          { date_start: { $gte: now } },
          {
            $and: [
              { date_start: { $lte: now } },
              { date_end: { $gte: now } }
            ]
          }
        ],
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `opening-times?${query}`
}
