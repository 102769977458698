<template>
  <div :class="$style.root">
    <div
      :class="$style.closed"
    >
      <template
        v-if="!isOpen"
      >
        {{ $t('openingTimes.closed') }}
      </template>
      <template
        v-else
      >
        {{ $t('openingTimes.open', { time: closingTime }) }}
      </template>
    </div>

    <ul
      :class="[$style.list, fontSizeClass]"
      v-if="openingTimes?.length"
    >
      <li :class="$style.listLabel">
        {{ $t('openingTimes.label') }}:
      </li>
      <li
        :class="$style.item"
        v-for="openingTime in openingTimes"
      >
        <div>
          {{ formatOpeningDays(openingTime?.attributes?.weekdays) }}:
          {{ formatOpeningTimes(openingTime?.attributes?.opening_time, openingTime?.attributes?.closing_time) }}
        </div>
        <div :class="$style.dateTimeRange">
          (<CoreDateTimeRangeComponent
            :dateStart="openingTime?.attributes?.date_start"
            :dateEnd="openingTime?.attributes?.date_end"
            format="DD.MM.YYYY"
          />)
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
const { locale: currentLocale } = useI18n()
import weekDays from "i18n-week-days"

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  openingTimes: {
    type: Array,
    required: false,
  },
  fontSizeOption: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'default'].includes(value),
  },
})

// 1
const fontSizeClass = computed(() => {
  return props?.fontSizeOption === 'small' ? 'font-size-small' : ''
})

// 2
const formatOpeningDays = (weekdays) => {
  if (!weekdays?.length) return

  const names = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  const days = weekdays?.map((day) => {
    return {
      index: names?.indexOf(day?.toLowerCase()?.trim()),
      value: day?.toLowerCase()?.trim()
    }
  })?.sort((a, b) => a?.index - b?.index)

  const groupedDays = Object.values(
    days?.reduce((acc, currentValue) => {
      let groupKey = currentValue?.index;

      const canBeGrouped = Object.values(acc)?.filter((group) => {
        return group[group?.length - 1]?.index === (currentValue?.index - 1)
      })

      if (!acc[groupKey] || canBeGrouped) {
        acc[groupKey] = [];
      }

      if (canBeGrouped?.length) {
        const index = Object.values(canBeGrouped)?.map(i => Object.values(i)[0]?.index)
        acc[index].push(currentValue);
      } else {
        acc[groupKey].push(currentValue);
      }

      return acc;
    }, {})
  )?.filter(i => i?.length > 0)

  return groupedDays?.map(group => {
    const localeDate = (index) => {
      return weekDays[currentLocale.value][index]
    }
    return group?.length > 1 ? `${localeDate(group[0]?.index)}–${localeDate(group[group?.length - 1]?.index)}` : localeDate(group[0]?.index)
  })?.filter(Boolean)?.join(', ')
}

const formatOpeningTimes = (timeOpen, timeClose) => {
  if (!timeOpen && !timeClose) return
  const open = timeOpen?.split(':')?.slice(0, 2)?.join(':')
  const close = timeClose?.split(':')?.slice(0, 2)?.join(':')
  if (timeOpen && timeClose) {
    return `${open}–${close}`
  } else if (timeOpen) {
    return open
  } else if (timeClose) {
    return close
  }
}

// 3
const mainStore = useMainStore()
const openingTimeData = await mainStore?.fetchOpeningTimesData()
const closingTime = formatOpeningTimes(openingTimeData?.openingsHoursForToday?.closingTime)
</script>

<style module>
.root {
  display: grid;
  grid-row-gap: var(--unit--spacer);
}

.closed {
  text-transform: uppercase;
}

.list {
  composes: reset-list from global;
}

.listLabel {
}

.item {
  display: flex;
  flex-direction: column;
}

.item:not(:last-of-type) {
  margin-bottom: var(--unit--spacer);
}

.dateTimeRange {
}
</style>
